<template>
  <div class="mb-5">
    <b-row class="my-2">
      <b-col md="12">
        <h1 class="text-center">
          Veuillez vous assurer que les informations entrées sont correctes puis
          passez au paiement de la demande.
        </h1>
      </b-col>
    </b-row>
    <b-card class="preview-document">
      <!-- RENSEIGNEMENTS GENERAUX  -->
      <div>
        <b-card id="renseignementsgeneraux">
          <b-card-header class="d-flex justify-content-between">
            <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
          </b-card-header>
          <hr>
          <b-card-body>
            <b-row>
              <!-- nom etablissement -->
              <b-col md="6">
                <!-- label-cols-md="3" -->
                <b-form-group
                  label="Établissement"
                >
                  <b-form-input
                    v-model="infos.nom_etablissement"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!-- ifu etab -->
              <b-col md="6">
                <b-form-group
                  label="Num IFU"
                >
                  <b-form-input
                    v-model="infos.ifu"
                    type="text"
                    v-digits-only="{length: 16}"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            
            <!-- adresse complète -->
            <b-form-group
              class="mt-2"
              label="Adresse Complète Établissement "
            >
              <b-form-input
                v-model="infos.adresse_etablissement"
                type="text"
                disabled
              />
            </b-form-group>

            <b-row>
              <!-- nom promo -->
              <b-col md="4">
                <b-form-group
                  label="Nom Promoteur"
                >
                  <b-form-input
                    v-model="infos.nom_etablissement"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!-- prenom promo -->
              <b-col md="4">
                <b-form-group
                  label="Prénom(s) Promoteur"
                >
                  <b-form-input
                    v-model="infos.prenoms_promoteur"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!-- nationalite promo -->
              <b-col md="4">
                <b-form-group
                  label="Nationalité Promoteur"
                >
                  <b-form-input
                    v-model="infos.nationalitePromoteur"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- telephone -->
            <b-row class="mt-2">
              <b-col
                class="mt-1"
                md="6"
              >
                <b-form-group
                  label="Numéro 1"
                >
                  <b-form-input
                    v-model="infos.telephone_etablissement"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                class="mt-1"
                md="6"
              >
                <b-form-group
                  label="Numéro 2"
                >
                  <b-form-input
                    v-model="infos.telephone_etablissement_2"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>

      <!-- EMPLOIS  -->
      <div>
        <b-card id="emplois">
          <b-card-header class="d-flex justify-content-between">
            <h3>EMPLOIS</h3>
          </b-card-header>
          <hr>
          <b-card-body>
            <!-- employés salariés -->
            <div class="table-responsive text-center">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      rowspan="2"
                    >
                      Nombre d'employés salariés
                    </th>
                    <th
                      scope="col"
                      colspan="2"
                    >
                      Hommes
                    </th>
                    <th
                      scope="col"
                      colspan="2"
                    >
                      Femmes
                    </th>
                    <th
                      scope="col"
                      colspan="2"
                    >
                      Total
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">
                      Temps plein
                    </th>
                    <th scope="col">
                      Temps partiel
                    </th>
                    <th scope="col">
                      Temps plein
                    </th>
                    <th scope="col">
                      Temps partiel
                    </th>
                    <th scope="col">
                      Temps plein
                    </th>
                    <th scope="col">
                      Temps partiel
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      Nationaux
                    </th>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.employesSalaries.hommeTempspleinNationaux
                        "
                        disabled
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.employesSalaries.hommeTempspartielNationaux
                        "
                        disabled
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.employesSalaries.femmeTempspleinNationaux
                        "
                        disabled
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.employesSalaries.femmeTempspartielNationaux
                        "
                        disabled
                      />
                    </td>
                    <td> 
                      {{
                        normalizeNumericValue(infos.employesSalaries.hommeTempspleinNationaux +
                          infos.employesSalaries.femmeTempspleinNationaux)
                      }}
                    </td>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.hommeTempspartielNationaux +
                          infos.employesSalaries.femmeTempspartielNationaux)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      Étrangers
                    </th>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.employesSalaries.hommeTempspleinEtrangers
                        "
                        disabled
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.employesSalaries.hommeTempspartielEtrangers
                        "
                        disabled
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.employesSalaries.femmeTempspleinEtrangers
                        "
                        disabled
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.employesSalaries.femmeTempspartielEtrangers
                        "
                        disabled
                      />
                    </td>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.hommeTempspleinEtrangers)+
                        normalizeNumericValue(infos.employesSalaries.femmeTempspleinEtrangers)
                      }}
                    </td>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.hommeTempspartielEtrangers) +
                        normalizeNumericValue(infos.employesSalaries.femmeTempspartielEtrangers)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      Total
                    </th>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.hommeTempspleinNationaux) +
                          normalizeNumericValue(infos.employesSalaries.hommeTempspleinEtrangers)
                      }}
                    </td>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.hommeTempspartielNationaux) +
                          normalizeNumericValue(infos.employesSalaries.hommeTempspartielEtrangers)
                      }}
                    </td>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.femmeTempspleinNationaux) +
                          normalizeNumericValue(infos.employesSalaries.femmeTempspleinEtrangers)
                      }}
                    </td>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.femmeTempspartielNationaux) +
                          normalizeNumericValue(infos.employesSalaries.femmeTempspartielEtrangers)
                      }}
                    </td>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.hommeTempspleinNationaux) +
                          normalizeNumericValue(infos.employesSalaries.femmeTempspleinNationaux ) 
                          + normalizeNumericValue(
                          infos.employesSalaries.hommeTempspleinEtrangers) +
                          normalizeNumericValue(infos.employesSalaries.femmeTempspleinEtrangers)
                      }}
                    </td>
                    <td>
                      {{
                        normalizeNumericValue(infos.employesSalaries.hommeTempspartielNationaux) +
                          normalizeNumericValue(infos.employesSalaries.femmeTempspartielNationaux 
                        ) 
                        + normalizeNumericValue(
                          infos.employesSalaries.hommeTempspartielEtrangers) +
                          normalizeNumericValue(infos.employesSalaries.femmeTempspartielEtrangers)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- nouveeaux employés du mois  -->
            <div class="table-responsive text-center mt-3">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col">
                      Salariés recrutés au cours du mois
                    </th>
                    <th scope="col">
                      Hommes
                    </th>
                    <th scope="col">
                      Femmes
                    </th>
                    <th scope="col">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      Nombre
                    </th>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.nouveauEmplois.hommes
                        "
                        disabled
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model.number="
                          infos.nouveauEmplois.femmes
                        "
                        disabled
                      />
                    </td>
                    <td>
                      {{
                        normalizeNumericValue (infos.nouveauEmplois.hommes) +
                        normalizeNumericValue(infos.nouveauEmplois.femmes)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-body>
        </b-card>
      </div>

      <!-- RENSEIGNEMENTS EXPLOITATION DU MOIS  -->
      <div>
        <b-card id="expoiltationmensuelle">
          <b-card-header class="d-flex justify-content-between">
            <h3>RENSEIGNEMENTS D'EXPLOITATION DU MOIS</h3>
          </b-card-header>
          <hr>
          <b-card-body>
            <!-- voyages organisés  -->
            <b-row>
              <b-col
                md="12"
                class="mt-1"
              >
                <h3>1- Voyages organisés et croisières tout compris</h3>
              </b-col>
              <!-- Partie gauche -->
              <b-col md="6">
                <div class="table-responsive text-center mt-1">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          Voyages Internes
                        </th>
                        <th>Nombres</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          Voyages d'entrée sans frais
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.voyagesInternes.sansFrais
                            "
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Voyages d'entrée avec frais
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.voyagesInternes.avecFrais
                            "
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Total
                        </th>
                        <td>
                          {{
                            normalizeNumericValue(infos.voyagesInternes.sansFrais +
                              infos.voyagesInternes.avecFrais)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <!-- Partie droite -->
              <b-col md="6">
                <div class="table-responsive text-center mt-1">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          Voyages Internationaux
                        </th>
                        <th>Nombres</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          Voyages d'entrée sans frais
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.voyagesInternationaux.entreeSansFrais
                            "
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Voyages d'entrée avec frais
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.voyagesInternationaux.entreeAvecFrais
                            "
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Voyages de sortie sans frais
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.voyagesInternationaux.sortieSansFrais
                            "
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Voyages de sortie avec frais
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.voyagesInternationaux.sortieAvecFrais
                            "
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Total
                        </th>
                        <td>
                          {{
                            normalizeNumericValue(infos.voyagesInternationaux.entreeSansFrais) +
                              normalizeNumericValue(infos.voyagesInternationaux.entreeAvecFrais) +
                              normalizeNumericValue(infos.voyagesInternationaux.sortieSansFrais) +
                              normalizeNumericValue(infos.voyagesInternationaux.sortieAvecFrais)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
            <!-- recette brutes -->
            <b-row>
              <b-col
                md="12"
                class="mt-2"
              >
                <h3>2- Recettes brutes</h3>
              </b-col>
              <!-- Partie gauche -->
              <b-col md="12">
                <div class="table-responsive text-center mt-1">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" />
                        <th>Montants</th>
                        <th>Pourcentages</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          Billetterie
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.recettesBrutes.billetterie
                            "
                            disabled
                          />
                        </td>
                        <td v-if="infos.recettesBrutes.billetterie > 0">
                          {{
                            (normalizeNumericValue(infos.recettesBrutes.billetterie) * 100) /
                              (
                                normalizeNumericValue(infos.recettesBrutes.billetterie) +
                                normalizeNumericValue(infos.recettesBrutes.voyagesOrganises) +
                                normalizeNumericValue(infos.recettesBrutes.croisieresToutCompris) +
                                normalizeNumericValue(infos.recettesBrutes.autresProduits)
                              )
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Voyages Organisés
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.recettesBrutes.voyagesOrganises
                            "
                            disabled
                          />
                        </td>
                        <td
                          v-if="infos.recettesBrutes.voyagesOrganises > 0"
                        >
                          {{
                            (normalizeNumericValue(infos.recettesBrutes.voyagesOrganises) * 100) /
                              (normalizeNumericValue(infos.recettesBrutes.billetterie) +
                                normalizeNumericValue(infos.recettesBrutes.voyagesOrganises) +
                                normalizeNumericValue(infos.recettesBrutes.croisieresToutCompris) +
                                normalizeNumericValue(infos.recettesBrutes.autresProduits))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Croisières Tout Compris
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.recettesBrutes.croisieresToutCompris
                            "
                            disabled
                          />
                        </td>
                        <td
                          v-if="
                            infos.recettesBrutes.croisieresToutCompris > 0
                          "
                        >
                          {{
                            (normalizeNumericValue(infos.recettesBrutes.croisieresToutCompris) *
                              100) /
                              (normalizeNumericValue(infos.recettesBrutes.billetterie) +
                                normalizeNumericValue(infos.recettesBrutes.voyagesOrganises) +
                                normalizeNumericValue(infos.recettesBrutes.croisieresToutCompris) +
                                normalizeNumericValue(infos.recettesBrutes.autresProduits))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Autres Produits
                        </th>
                        <td>
                          <b-form-input
                            v-model.number="
                            infos.recettesBrutes.autresProduits
                            "
                            disabled
                          />
                        </td>
                        <td v-if="infos.recettesBrutes.autresProduits > 0">
                          {{
                            (normalizeNumericValue(infos.recettesBrutes.autresProduits) * 100) /
                              (normalizeNumericValue(infos.recettesBrutes.billetterie) +
                                normalizeNumericValue(infos.recettesBrutes.voyagesOrganises) +
                                normalizeNumericValue(infos.recettesBrutes.croisieresToutCompris) +
                                normalizeNumericValue(infos.recettesBrutes.autresProduits))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Recettes Brutes Totales
                        </th>
                        <td>
                          {{
                            normalizeNumericValue(infos.recettesBrutes.billetterie )+
                              normalizeNumericValue(infos.recettesBrutes.voyagesOrganises) +
                              normalizeNumericValue(infos.recettesBrutes.croisieresToutCompris )+
                              normalizeNumericValue(infos.recettesBrutes.autresProduits)
                          }}
                        </td>
                        <td
                          v-if="
                            infos.recettesBrutes.billetterie > 0 ||
                              infos.recettesBrutes.voyagesOrganises > 0 ||
                              infos.recettesBrutes.croisieresToutCompris >
                              0 ||
                              infos.recettesBrutes.autresProduits
                          "
                        >
                          100 %
                        </td>
                        <td v-else>
                          0 %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <!-- charge salariales  -->
              <b-col md="6">
                <b-form-group
                  class=""
                  label="Charges salariales (FCFA)"
                >
                  <b-form-input
                    v-model.number="
                    infos.chargesSalariales
                    "
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!-- autres charges  -->
              <b-col md="6">
                <b-form-group
                  class=""
                  label="Autres Dépenses (FCFA)"
                >
                  <b-form-input
                    v-model.number="
                      infos.autresDepense
                    "
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col
                md="12"
                class="mt-2"
              >
                <h3>Pièces jointes</h3>
              </b-col>
              <b-col 
                class="" v-for="(piece, indexP ) in infos.piecesJointesList" :key="indexP"
              >
                <b-row v-if="piece.value">
                  <b-col md="4">
                    {{ piece.label }}
                  </b-col>
                  <b-col md="8">
                    {{ piece.value.name }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>

      <!-- buttons -->
      <b-row class="px-1 mt-2">
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="secondary"
            class="w-100"
            @click="() => {
              this.$emit('close', {});
            }"
          >
            Retourner sur le formulaire
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="w-100"
            @click="() => {
              this.$emit('save', {});
              this.isSavingForm = true
            }"
          >
            <span
              v-if="isSavingForm"
              class="d-flex align-items-center"
            >
              <div class="mr-2"><b-spinner /></div>
              <span class=""> En cours de paiement... </span>
            </span>
            <span v-if="!isSavingForm"> Procéder au paiement </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal backdrop -->
    <!-- <b-modal
      :ref="sectionEditionModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Modifier cette section"
      size="xl"
    >
      <component
        :is="sectionToEditComponent"
        :input-data="sectionToEditData"
        :show-edition-buttons="true"
        @onFormSuccess="onEditionFormSuccess($event)"
        @onCancel="hideSectionEditionModal"
      />
    </b-modal> -->
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  VBTooltip,
  BImg,
  BSpinner,
  BModal,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import AppLogo from '@/components/AppLogo.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from '@/services/utils/utils.service'
import formulairesStoreModule from '@/store/formulaire'


import { registerStoreModule, unregisterStoreModule } from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulairesStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  }, 
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    AppLogo,
    VBTooltip,
    BImg,
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isSavingForm: false,
      preview: null,
      isProcessingProvidedInput: true,
      sectionEditionModalRef: 'edit-section-modal',
      sectionToEditComponent: null,
      // sectionToEditData: null,
      editIconGreen: require('@/assets/images/icons/edit vert.png'),
      editIcon: require('@/assets/images/icons/edit.png'),
      currentUserId: localstorageService.getUserId(),
    }
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') { return 'layout-content-renderer-left' }
      if (rendererType === 'sidebar-left-detached') { return 'layout-content-renderer-left-detached' }
      return 'layout-content-renderer-default'
    },
    
  },
  mounted() {
    console.log('this.infos::: ', this.infos);
    // if (this.$route.params.data && this.$route.params.preview) {
    //   const { data, isEditionOperation } = this.$route.params
    //   this.infos = {
    //     ...data,
    //     ...(isEditionOperation && { editor: this.currentUserId }),
    //   }
    //   this.preview = this.$route.params.preview
    //   // console.log(this.preview, this.infos)
    //   // console.log('ok')
    // } else {
    //   // this.infos = validInput
    //   // console.log('ok')
    //   this.$router.push({
    //     name: 'formulaires-create',
    //     params: { slug: this.$route.params.slug },
    //   })
    // }
  },
  methods: {
    ...mapActions('formulaire', {
      addFormulaire: 'addFormulaire',
      updateFormulaire: 'updateFormulaire',
    }),

    // ------------------------------------------------------
    onEditionFormSuccess(evt) {
      this.infos = { ...this.infos, ...evt }
      this.hideSectionEditionModal()
      // console.log('onFormsuccess', evt)
    },
    // eslint-disable-next-line consistent-return
    getSectionToEditComponent(section) {
      switch (section) {
        case 'header':
          return 'FormHeader'
        case 'renseignements-generaux':
          return 'FormRenseignementsGeneraux'
        case 'emplois-salaries':
          return 'FormSalaries'
        case 'emplois-nouveaux':
          return 'FormNouveauxEmployesDuMois'
        case 'emplois-non-salaries':
          return 'FormNonSalaries'
        case 'exploitation-voyages':
          return 'FormExploitationVoyages'
        case 'exploitation-recettes':
          return 'FormExploitationRecettes'
        case 'exploitation-commissions':
          return 'FormExploitationCommissions'
        default:
          break
      }
    },
    save() {
      const { currentUserId, etablissementDetails, userRoleId } = utilsService.currentUserUtils()
      // console.log('userRoleId: ', userRoleId)
      // return

      this.isSavingForm = true
      // console.log('this.infos: ', this.infos)
      const { isEditionOperation } = this.$route.params
      const successMessage = !isEditionOperation
        ? 'Formulaire enregistré avec succès'
        : ' Opération réussie'
      const serviceMethode = isEditionOperation ? 'action_updateFormulaire' : 'action_addFormulaire'
      const { _id } = this.infos
      delete this.infos._id
      if(this.infos.adresseComplete === null || this.infos.adresseComplete === '' ) delete this.infos.adresseComplete
      if(this.infos.numIFU === null || this.infos.numIFU === '' ) delete this.infos.numIFU
      if(this.infos.referenceAgrement === null || this.infos.referenceAgrement === '' ) delete this.infos.referenceAgrement
      
      console.log('this.infos: ', this.infos);

      const data = {
        formType: 'forms-stats-agences',
        ...(isEditionOperation && { id: _id }),
        data: {
          ...this.infos,
          numIFU:this.infos.numIFU 
          // auteur: this.infos.auteur && this.infos.auteur._id,
          // ...(!isEditionOperation && { editor: this.currentUserId }),
        },
      }
      let userData = localstorageService.getUserData()
      if (isEditionOperation) {
        data.data.editor = this.currentUserId
      } else {
        data.data.auteur = currentUserId
        // data.data.auteurRole = userRoleId
        data.data.auteurRole = userData.role._id
      }
      // if( currentUserId ) data.data.auteur = currentUserId
      if (etablissementDetails._id) data.data.etablissement = etablissementDetails._id
      if (etablissementDetails.secteur) data.data.secteur = etablissementDetails.secteur

      // // console.log(
      //   '------------------------- finale data data before save: ===> ',
      //   data,
      // )

      this[serviceMethode](data)
        .then(response => {
          // console.log('response create form: ', response)
          if (response.status === 200) {
            if (
              this.$store.state.auth
              && this.$store.state.auth.isAuthenticated
            ) {
              // this.$router.push({
              //   name: 'formulaires-list',
              //   params: { slug: this.$route.params.slug },
              // })

              // let selectedForm = utilsService.getFormulaireBySlug(this.$route.params.slug)
              // console.log('selectedForm: 🔵🔴🔵🔴 ', selectedForm)
              // const { params } = selectedForm.createRouteProps
              // this.$router.push({
              //   ...selectedForm.createRouteProps,
              //   params: {
              //     ...params,
              //   },
              // })

              this.$router.push({
                name: 'dashboard',
              })

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: successMessage,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            } else {
              this.$router.push({
                name: 'home-public',
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: successMessage,
                  icon: 'CheckIcon',
                  variant: 'success',
                  // text: 'Bienvenue !',
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est suvenue, veuillez rééssayer',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          this.isSavingForm = false
        })
        .catch(error => {
          this.isSavingForm = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                error.message || 'Une erreur est suvenue, veuillez rééssayer',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].show()
    },
    hideSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].hide()
    },
    // editSection(section) {
    //   this.sectionToEditComponent = this.getSectionToEditComponent(section)
    //   this.sectionToEditData = JSON.parse(JSON.stringify(this.infos))

    //   this.showSectionEditionModal()
    // },
    edit() {
      console.log( '📌' , this.infos);
      this.$router.push({
        name: 'formulaires-create',
        params: { slug: this.$route.params.slug, data: this.infos },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/themes/bordered-layout.scss";

.customborder1 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 15px 35px;
}
.customborder2 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 0 35px 15px;
}

table {
  td,
  th,
  tr {
    text-align: left;
  }
}
thead {
  th {
    background-color: #0e6258 !important;
    color: white;
  }
}
</style>
