<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <b-card>
          <b-card-header>
            <b-row no-gutters class="w-100">
              <b-col md="12">
                <!-- <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-popover.hover.focus="
                        '⚪️La fiche statistique mensuelle des agences de voyage est éditable par les promoteurs d’établissements et leurs agents. Elle permet de collecter des données sur le fonctionnement mensuel de l\'agence.⚪️ La fiche est remplie une fois par mois et est visible par le super utilisateur et les agents du MTCA.'
                      "
                      size="lg"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Information"
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div> -->
              </b-col>
              <b-col md="2">
                <b-card-img
                  :src="require('@/assets/images/illustration/hebergement.png')"
                  alt="Image"
                  class="rounded-0 my-2"
                />
              </b-col>
              <b-col
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h4 class="m-auto text-center">Hébergement</h4>
                  <br />
                  <h1 class="m-auto text-center">
                    Demande d’autorisation d’exploitation et de premier
                    classement
                  </h1>
                </div>
              </b-col>
            </b-row>
          </b-card-header>
          <content-loader
            v-if="isCheckingForLastForm"
            view-box="0 0 400 460"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <circle cx="29" cy="30" r="17" />
            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
            <rect x="-7" y="60" rx="2" ry="2" width="408" height="132"
          /></content-loader>
          <b-card-body v-if="!isCheckingForLastForm" id="entete" class="mt-2">
            <FormHeader
              :input-data="initialsValues"
              @onFormSuccess="
                ($event) => {
                  infos = { ...infos, ...$event };
                }
              "
            />
          </b-card-body>
        </b-card>
      </div>
      <b-row v-if="!isCheckingForLastForm">
        <!-- navigation -->
        <b-col lg="3" class="d-none d-lg-block">
          <div>
            <b-card>
              <b-list-group>
                <!-- <b-list-group-item href="#entete">
                  EN TETE
                </b-list-group-item> -->
                <b-list-group-item href="#renseignementsgeneraux">
                  RENSEIGNEMENTS GÉNÉRAUX
                </b-list-group-item>
                <b-list-group-item href="#emplois"> EMPLOIS </b-list-group-item>
                <b-list-group-item href="#expoiltationmensuelle">
                  RENSEIGNEMENTS EXPLOITATION DU MOIS
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              @click="send_to_preview()"
            >
              Enregistrer
            </b-button>
          </div>
        </b-col>
        <!-- body -->
        <b-col lg="9" class="pb-2">
          <!-- RENSEIGNEMENTS GENERAUX  -->
          <div>
            <b-card id="renseignementsgeneraux">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <b-row>
                  <!-- nom etablissement -->
                  <b-col md="6">
                    <!-- label-cols-md="3" -->
                    <b-form-group label="Établissement">
                      <b-form-input
                        v-model="infos.nom_etablissement"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- ifu etab -->
                  <b-col md="6">
                    <b-form-group label="Num IFU">
                      <b-form-input
                        v-model="infos.ifu"
                        type="text"
                        v-digits-only="{ length: 16 }"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- adresse complète -->
                <b-form-group
                  class="mt-2"
                  label="Adresse Complète Établissement "
                >
                  <b-form-input
                    v-model="infos.adresse_etablissement"
                    type="text"
                    disabled
                  />
                </b-form-group>

                <b-row>
                  <!-- nom promo -->
                  <b-col md="4">
                    <b-form-group label="Nom Promoteur">
                      <b-form-input
                        v-model="infos.nom_etablissement"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- prenom promo -->
                  <b-col md="4">
                    <b-form-group label="Prénom(s) Promoteur">
                      <b-form-input
                        v-model="infos.prenoms_promoteur"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- nationalite promo -->
                  <b-col md="4">
                    <b-form-group label="Nationalité Promoteur">
                      <validation-provider v-slot="{ errors }" rules="required">
                        <v-select
                          v-model="infos.nationalitePromoteur"
                          type="text"
                          :options="pays"
                          label="name"
                          placeholder=""
                          @input="update_infos('nationalitePromoteur')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- telephone -->
                <b-row class="mt-2">
                  <b-col class="mt-1" md="6">
                    <b-form-group label="Numéro 1">
                      <b-form-input
                        v-model="infos.telephone_etablissement"
                        @change="update_infos('telephone_etablissement')"
                        type="text"
                        v-phonenumber-field
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="mt-1" md="6">
                    <b-form-group label="Numéro 2">
                      <b-form-input
                        v-model="infos.telephone_etablissement_2"
                        @change="update_infos('telephone_etablissement_2')"
                        type="text"
                        v-phonenumber-field
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </div>

          <!-- EMPLOIS  -->
          <div>
            <b-card id="emplois">
              <b-card-header class="d-flex justify-content-between">
                <h3>EMPLOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- employés salariés -->
                <div class="table-responsive text-center">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.hommeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .hommeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.femmeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .femmeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries.femmeTempspleinNationaux
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielNationaux
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.hommeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers
                              "
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.femmeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinEtrangers
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspartielEtrangers
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspartielNationaux
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspleinNationaux
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspartielNationaux
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspleinNationaux
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinEtrangers
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspartielNationaux
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspartielNationaux
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspartielEtrangers
                            ) +
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- nouveeaux employés du mois  -->
                <div class="table-responsive text-center mt-3">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Salariés recrutés au cours du mois</th>
                        <th scope="col">Hommes</th>
                        <th scope="col">Femmes</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nombre</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.hommes"
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.femmes"
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(infos.nouveauEmplois.hommes) +
                            normalizeNumericValue(infos.nouveauEmplois.femmes)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <!-- RENSEIGNEMENTS EXPLOITATION DU MOIS  -->
          <div>
            <b-card id="expoiltationmensuelle">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS D'EXPLOITATION DU MOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- voyages organisés  -->
                <b-row>
                  <b-col md="12" class="mt-1">
                    <h3>1- Voyages organisés et croisières tout compris</h3>
                  </b-col>
                  <!-- Partie gauche -->
                  <b-col md="6">
                    <div class="table-responsive text-center mt-1">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Voyages Internes</th>
                            <th>Nombres</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Voyages d'entrée sans frais</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternes.sansFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages d'entrée avec frais</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternes.avecFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Total</th>
                            <td>
                              {{
                                normalizeNumericValue(
                                  infos.voyagesInternes.sansFrais +
                                    infos.voyagesInternes.avecFrais
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <!-- Partie droite -->
                  <b-col md="6">
                    <div class="table-responsive text-center mt-1">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Voyages Internationaux</th>
                            <th>Nombres</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Voyages d'entrée sans frais</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternationaux.entreeSansFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages d'entrée avec frais</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternationaux.entreeAvecFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages de sortie sans frais</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternationaux.sortieSansFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages de sortie avec frais</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.voyagesInternationaux.sortieAvecFrais
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Total</th>
                            <td>
                              {{
                                normalizeNumericValue(
                                  infos.voyagesInternationaux.entreeSansFrais
                                ) +
                                normalizeNumericValue(
                                  infos.voyagesInternationaux.entreeAvecFrais
                                ) +
                                normalizeNumericValue(
                                  infos.voyagesInternationaux.sortieSansFrais
                                ) +
                                normalizeNumericValue(
                                  infos.voyagesInternationaux.sortieAvecFrais
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
                <!-- recette brutes -->
                <b-row>
                  <b-col md="12" class="mt-2">
                    <h3>2- Recettes brutes</h3>
                  </b-col>
                  <!-- Partie gauche -->
                  <b-col md="12">
                    <div class="table-responsive text-center mt-1">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" />
                            <th>Montants</th>
                            <th>Pourcentages</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Billetterie</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.recettesBrutes.billetterie
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.recettesBrutes.billetterie > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.autresProduits
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Voyages Organisés</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.recettesBrutes.voyagesOrganises
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td
                              v-if="infos.recettesBrutes.voyagesOrganises > 0"
                            >
                              {{
                                (normalizeNumericValue(
                                  infos.recettesBrutes.voyagesOrganises
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.autresProduits
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Croisières Tout Compris</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.recettesBrutes.croisieresToutCompris
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td
                              v-if="
                                infos.recettesBrutes.croisieresToutCompris > 0
                              "
                            >
                              {{
                                (normalizeNumericValue(
                                  infos.recettesBrutes.croisieresToutCompris
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.autresProduits
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Autres Produits</th>
                            <td>
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <b-form-input
                                  v-model.number="
                                    infos.recettesBrutes.autresProduits
                                  "
                                  v-digits-only="{ length: 16 }"
                                  type="number"
                                  min="0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </td>
                            <td v-if="infos.recettesBrutes.autresProduits > 0">
                              {{
                                (normalizeNumericValue(
                                  infos.recettesBrutes.autresProduits
                                ) *
                                  100) /
                                (normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.voyagesOrganises
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.croisieresToutCompris
                                  ) +
                                  normalizeNumericValue(
                                    infos.recettesBrutes.autresProduits
                                  ))
                              }}
                              %
                            </td>
                            <td v-else>0</td>
                          </tr>
                          <tr>
                            <th scope="row">Recettes Brutes Totales</th>
                            <td>
                              {{
                                normalizeNumericValue(
                                  infos.recettesBrutes.billetterie
                                ) +
                                normalizeNumericValue(
                                  infos.recettesBrutes.voyagesOrganises
                                ) +
                                normalizeNumericValue(
                                  infos.recettesBrutes.croisieresToutCompris
                                ) +
                                normalizeNumericValue(
                                  infos.recettesBrutes.autresProduits
                                )
                              }}
                            </td>
                            <td
                              v-if="
                                infos.recettesBrutes.billetterie > 0 ||
                                infos.recettesBrutes.voyagesOrganises > 0 ||
                                infos.recettesBrutes.croisieresToutCompris >
                                  0 ||
                                infos.recettesBrutes.autresProduits
                              "
                            >
                              100 %
                            </td>
                            <td v-else>0 %</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- charge salariales  -->
                  <b-col md="6">
                    <b-form-group class="" label="Charges salariales (FCFA)">
                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model.number="infos.chargesSalariales"
                          type="number"
                          v-digits-only="{ length: 16 }"
                          min="0"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- autres charges  -->
                  <b-col md="6">
                    <b-form-group class="" label="Autres Dépenses (FCFA)">
                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model.number="infos.autresDepense"
                          type="number"
                          v-digits-only="{ length: 16 }"
                          min="0"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- pieces_jointe -->
                <b-form-group
                  v-for="(fileInput, index) in piecesJointesList"
                  :key="index"
                  :label-for="fileInput.label + index"
                  :label="fileInput.label"
                >
                  <!-- Styled -->
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-file
                      v-model="piecesJointesList[index].value"
                      placeholder="Veuillez choisir un fichier"
                      drop-placeholder="Déposez ici..."
                      accept="application/pdf"
                      :state="
                        !isValidFile(piecesJointesList[index].value)
                          ? false
                          : null
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div>
                    <small
                      v-if="!isValidFile(piecesJointesList[index].value)"
                      class="text-danger"
                      >La taille de ce fichier dépasse 1Mo
                    </small>
                  </div>
                </b-form-group>
              </b-card-body>
            </b-card>
          </div>

          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-2"
              @click="send_to_preview()"
            >
              Enregistrer
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- <TipsModalCreateFromLastRecord /> -->
    <b-modal
      :ref="tipsModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Créer un formulaire"
      size="lg"
    >
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir du dernier formulaire enregistré
            </h3>
            <p class="my-1">
              Vous avez la possibilté de récupérer les données du dernier
              formulaire que vous avez enregistré pour remplir ce nouveau
              formulaire.
            </p>
            <b-button
              variant="outline-primary"
              class="float-right"
              @click="fillFormWithLastRecord"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">Créer a partir d'un formulaire vierge</h3>
            <p class="my-1">
              Vous avez la possibilté de remplir le formulaire de zéro.
            </p>

            <b-button
              variant="outline-primary"
              class="float-right"
              @click="hideModal"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>

    <!-- modal for npreview -->
    <b-modal
      id="modal-preview"
      ref="modal-preview"
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Quelle opération souhaitez-vous effectuer ?"
      centered
      size="xl"
    >
      <PreviewUEH
        :infos="infos"
        @save="
          ($event) => {
            validate_payment();
          }
        "
        @close="
          ($event) => {
            this.$refs['modal-preview'].hide();
          }
        "
      />
    </b-modal>
    <!-- modal for payment -->
    <b-modal
      id="modal-payment"
      ref="modal-payment"
      no-close-on-backdrop
      title="Procédez au paiement pour. valider la demande."
      hide-footer
      centered
    >
      <!-- paymentInitialsDatas = {{ paymentInitialsDatas }} -->
      <PaymentWidget
        :initials-datas="paymentInitialsDatas"
        @onPaymentComplete="validate_form($event)"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
  BModal,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
  BFormFile,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
// import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
// imports for validation
// const store = require('store')

import FormHeader from "../../components/FormHeader.vue";
import PreviewUEH from "./PreviewUEH.vue";
import PaymentWidget from "@/components/PaymentWidget.vue";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulairesStoreModule from "@/store/formulaire";
import paymentsStroreModule from "@/store/payments";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    // BCardText,
    // BForm,
    BInputGroupPrepend,
    BInputGroup,
    ContentLoader,
    BModal,
    FormHeader,
    PreviewUEH,
    BFormFile,

    // TipsModalCreateFromLastRecord,
    PaymentWidget,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  mixins: [heightTransition],
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaires", module: formulairesStoreModule },
      { path: "payments", module: paymentsStroreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);
    return { requiredStoreModules };
  },
  infos_id: {
    type: Number,
    default: () => null,
  },
  props: {
    demande: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: "formstatagence",
      email,
      required,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      typesEts: [
        { text: "Agence de voyage", value: "Agence de voyage" },
        { text: "Tour Operator", value: "Tour Operator" },
        { text: "Agence réceptive", value: "Agence réceptive" },
      ],
      agrementOptions: ["non", "oui"],
      autofillableFields: [
        {
          name: "annee",
        },
        {
          name: "departement",
        },
        {
          name: "commune",
        },
        {
          name: "arrondissement",
        },
        {
          name: "quartier",
        },
        {
          name: "nom_etablissement",
        },
        {
          name: "ifu",
        },
        {
          name: "nom_promoteur",
        },
        {
          name: "prenoms_promoteur",
        },
        {
          name: "nationalite_promoteur",
        },
        {
          name: "adresse_etablissement",
        },
        {
          name: "telephone_etablissement",
        },
        {
          name: "typeEtablissement",
        },
      ],
      initialsValues: {},
      infos: {
        // annee: '',
        // mois: '',
        departement: "",
        commune: "",
        arrondissement: "",
        quartier: "",
        nom_etablissement: "",
        ifu: "",
        nom_etablissement: "",
        adresse_etablissement: "",
        prenoms_promoteur: "",
        nationalitePromoteur: "",
        telephone_etablissement: "",
        telephone_etablissement_2: "",
        typeEtablissement: "",
        referenceAgrement: "",
        // employés
        employesSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        nouveauEmplois: {
          hommes: null,
          femmes: null,
        },
        // renseignement exploitation
        voyagesInternes: {
          sansFrais: null,
          avecFrais: null,
        },
        voyagesInternationaux: {
          entreeSansFrais: null,
          entreeAvecFrais: null,
          sortieSansFrais: null,
          sortieAvecFrais: null,
        },
        recettesBrutes: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          autresProduits: null,
        },
        chargesSalariales: null,
        autresDepense: null,
      },

      piecesJointesList: [
        { label: "Carte d'Identité", key: "cni", value: null },
        // { label: "IFU", key: 'ifu', value: null },
      ],
      valid_piecesJointesList: [],

      validOutput: {
        arrondissement: "2ème Arrondissement",
        quartier: "",
        nationalitePromoteur: "Bénin",
        telephone_etablissement_2: 20224412,
        typeEtablissement: "",
        referenceAgrement: "",
        employesSalaries: {
          hommeTempspleinNationaux: 1,
          hommeTempspartielNationaux: 3,
          hommeTempspleinEtrangers: 3,
          hommeTempspartielEtrangers: 3,
          femmeTempspleinNationaux: 3,
          femmeTempspartielNationaux: 3,
          femmeTempspleinEtrangers: 3,
          femmeTempspartielEtrangers: 3,
        },
        nouveauEmplois: {
          hommes: 3,
          femmes: 3,
        },
        voyagesInternes: {
          sansFrais: 3,
          avecFrais: 3,
        },
        voyagesInternationaux: {
          entreeSansFrais: 3,
          entreeAvecFrais: 3,
          sortieSansFrais: 3,
          sortieAvecFrais: 3,
        },
        recettesBrutes: {
          billetterie: 3,
          voyagesOrganises: 3,
          croisieresToutCompris: 3,
          autresProduits: 3,
        },
        chargesSalariales: 3,
        autresDepense: 3,
      },

      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,
      user_data: null,
    };
  },
  computed: {
    ...mapGetters("payments", {
      getter_showPaymentModal: "getter_show",
      getter_paymentInitialsDatas: "getter_paymentInitialsDatas",
      getter_paymentResponse: "getter_paymentResponse",
      getter_isCompleted: "getter_isCompleted",
    }),
    // ---------------------------------
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left")
        return "layout-content-renderer-left";
      if (rendererType === "sidebar-left-detached")
        return "layout-content-renderer-left-detached";
      return "layout-content-renderer-default";
    },
    paymentInitialsDatas() {
      return {
        api_keys: {
          fedapay_public_api_key: "pk_live_NfqfnXRoCeMpYnAuvHjaazky",
          kkiapay_public_api_key: "d84a24205e8d11ec961391121972f828",
        },
        amount:
          this.demande.montant_demande > 0 ? this.demande.montant_demande : 0,
        email: this.user_data.email,
        phone: "",
        lastname: this.user_data.lastname,
        firstname: this.user_data.firstname,
        fullname: this.user_data.lastname + " " + this.user_data.firstname,
      };
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.init();
      },
    },
    "infos.departement": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.commune = ""), (this.communes = []);
        this.loadCommunes();
      },
    },
    "infos.commune": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.arrondissement = ""), (this.arrondissements = []);
        this.loadArrondissements();
      },
    },
    "infos.arrondissement": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.quartier = ""), (this.quartiers = []);
        this.loadNeighborhoods();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    // this.getListTypesEtablissement()
    // this.loadDepartments()
    // this.loadCommunes()
    // this.loadArrondissements()
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaires", {
      action_getLastFormulaireCreatedByUser: "getLastFormulaireCreatedByUser",
      action_fetchFormulaires: "fetchFormulaires",
      action_addFormulaire: "addFormulaire",
      action_updateFormulaire: "updateFormulaire",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    ...mapMutations("payments", {
      action_setter_show: "setter_show",
    }),
    // --------------------------------------------------------------------
    init() {
      const { demande_id } = this.$route.params;
      if (!demande_id) {
        // new demande
        this.init_treatment("new");
        return;
        this.action_addFormulaire()
          .then((response) => {
            console.log("response createdemande: ", response);
            if (response.status === 200) {
              this.infos_id = response.data.data.id;
              this.init_treatment("new");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Une erreur est suvenue, veuillez rééssayer",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.goBack();
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  error.message || "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.goBack();
          });
      } else {
        // old demande
        this.action_getFormulaire({ id: demande_id })
          .then((response) => {
            console.log("response action_getFormulaire: ", response);
            if (response.status === 200) {
              this.infos_id = response.data.data.id;
              this.init_treatment("old");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Une erreur est suvenue, veuillez rééssayer",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.goBack();
            }
            this.isSavingForm = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  error.message || "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.goBack();
          });
      }
    },
    init_treatment(status, data) {
      if (status === "old") {
        this.isCheckingForLastForm = false;
        this.isFillingForm = true;
        // this.infos = { ...this.formInput }
        this.initialsValues = this.customCloneDeep(data);
        setTimeout(() => {
          this.isFillingForm = false;
        }, 1000);
      }
      if (status === "new") {
        const { userProfile, userData } = utilsService.currentUserUtils();
        this.user_data = userData;
        const autofillableFields = {};
        this.autofillableFields.map((e) => {
          let foundItem = userProfile[e.name];
          if (!foundItem) foundItem = userProfile[e.key];
          else autofillableFields[e.name] = foundItem;
          return e;
        });

        // autofillableFields.annee = new Date().getFullYear()
        // autofillableFields.mois = this.mois[new Date().getMonth()]

        this.infos = { ...this.infos, ...autofillableFields };
        this.initialsValues = this.customCloneDeep(this.infos);

        setTimeout(() => {
          this.isCheckingForLastForm = false;
        }, 1000);
        this.infos = {
          ...this.infos,
          ...this.validOutput,
        };
      }

      // this.annees = utilsService.getListYears()
      // this.mois = utilsService.getListMonths()
      // this.mois = this.removeinvalidMonth(this.mois)
      this.pays = utilsService.getListCountries();
    },
    loadDepartments() {
      this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
        })
        .catch((err) => {});
    },
    loadCommunes() {
      this.action_loadCommunes(this.infos.departement)
        .then((res) => {
          this.communes = res;
        })
        .catch((err) => {});
    },
    loadArrondissements() {
      this.action_loadArrondissements(this.infos.commune)
        .then((res) => {
          this.arrondissements = res;
        })
        .catch((err) => {});
    },
    loadNeighborhoods() {
      this.action_loadNeighborhoods(this.infos.arrondissement)
        .then((res) => {
          this.quartiers = res;
        })
        .catch((err) => {});
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          // this.typesEts = res.data.typeetablissements || [];
        })
        .catch((err) => {});
    },
    showModal() {
      this.$refs[this.tipsModalRef].show();
    },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithLastRecord() {
      this.isFillingForm = true;
      this.infos = this.lastRecordedForm;
      setTimeout(() => {
        this.isFillingForm = false;
      }, 1000);
      this.hideModal();
    },
    isValidFile(file) {
      if (file && file.size && file.size > 5000000) {
        return false;
      }
      return true;
    },
    update_infos(attribut) {
      // console.log('attribut::: 🚧', attribut, this.infos[attribut])
      return;
      this.action_updateFormulaire({
        id: this.infos_id,
        data: { attribut: this.infos[attribut] },
      })
        .then((response) => {
          console.log("response action_updateFormulaire: ", response);
          if (response.status === 200) {
            console.log("::: ", response.data.data);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                error.message || "Une erreur est suvenue, veuillez rééssayer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    send_to_preview() {
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          const canSend = true;
          // control files -------------------------------------------------------
          const files = this.piecesJointesList.map((e) => e).filter((e) => !!e);
          if (files.length > 0) {
            const allFilesAreValid = files.map((file) =>
              this.isValidFile(file.value)
            );
            for (let index = 0; index < allFilesAreValid.length; index++) {
              const element = allFilesAreValid[index];
              if (element === false) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title:
                      "Vos fichiers ne sont pas au bon format. Veuillez renseigner des fichiers de 5Mo maximum, au format PDF",
                    icon: "CheckIcon",
                    variant: "danger",
                  },
                });
                this.isLoading = false;
                return;
              }
            }
            this.valid_piecesJointesList = files;
          }

          // check validity of IFU
          // if (form.ifu != "") {
          //   this.invalideIfu = false;
          //   const res = await this.checkIFU({
          //     numeroIfu: form.ifu,
          //   });
          //   if (!res.data.success) {
          //     canSend = false;
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: "Numéro IFU Invalide",
          //         icon: "AlertTriangleIcon",
          //         variant: "danger",
          //       },
          //     });
          //     this.invalideIfu = true;
          //   }
          // }

          // return
          this.infos.piecesJointesList = this.piecesJointesList;
          console.log("this.infos:::================= ✅✅✅", this.infos);
          if (canSend) {
            this.$refs["modal-preview"].show();
            // this.$emit('onFormSucess', {
            //   routerParams: { preview: true, data: form },
            // })
          }
        } else {
          const errorFirst = this.$el.querySelector(".is-invalid");
          if (errorFirst) {
            errorFirst.scrollIntoView({ behavior: "instant" });
            errorFirst.focus();
          }
        }
      });
    },
    validate_payment() {
      this.$refs["modal-payment"].show();
    },
    validate_form(event) {
      let data = {
        paiement: this.getter_paymentResponse.data || {},
      };
      for (
        let index = 0;
        index < this.valid_piecesJointesList.length;
        index++
      ) {
        const piece = this.valid_piecesJointesList[index];
        // formData.append(piece.label, piece.value)
        data[piece.key] = piece.value;
      }
      console.log("data::: ✅", data);
      this.$router.push({
        name: "formulaires-list-ue",
        // params: { slug: this.$route.params.slug },
      });
      return;

      console.log("validate_form::: toto", event);
      const { closed } = event;

      if (!closed.isSuccess) {
        // on payment failed
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Paiement échoué. Veuillez essayer à nouveau.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } else {
        // update demande
        console.log("validate_form::: oooook", this.getter_paymentResponse);
        let data = {
          paiement: this.getter_paymentResponse.data || {},
        };
        for (
          let index = 0;
          index < this.valid_piecesJointesList.length;
          index++
        ) {
          const piece = this.valid_piecesJointesList[index];
          console.log("piece::: ", piece.label, piece.value);
          // formData.append(piece.label, piece.value)
          data[piece.key] = piece.value;
        }
        console.log("data::: ✅", data);

        return;
        this.action_updateFormulaire({ id: this.infos_id, formData })
          .then((response) => {
            console.log("response action_updateFormulaire: ", response);
            if (response.status === 200) {
              console.log("::: ", response.data.data);
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Une erreur est suvenue, veuillez rééssayer",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  error.message || "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>
