<template>
  <div>
    <b-row>
      <!-- annee -->
      <!-- <b-col sm="6">
        <b-form-group label="Année">
          <v-select
            v-model="form.annee"
            type="text"
            :options="annees"
            placeholder="Renseignez l'année en cours"
            required="true"
          />
          <div v-if="$v.form.annee.$error">
            <small v-if="$v.form.annee.required.$invalid" class="text-danger"
              >Ce champ est requis</small
            >
          </div>
        </b-form-group>
      </b-col> -->
      <!-- mois -->
      <!-- <b-col sm="6">
        <b-form-group label="Mois">
          <v-select
            v-model="form.mois"
            type="text"
            :options="mois"
            placeholder="Sélectionnez le mois"
            required="true"
          />
        </b-form-group>
      </b-col> -->
      <b-col sm="6">
        <!-- departement -->
        <b-form-group label="Département">
          <b-form-input v-model="form.departement" type="text" disabled />
          <!-- <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-select
              v-model="form.departement"
              type="text"
              :options="departements"
              label="name"
              placeholder="Sélectionnez votre département"
            />
            <small class="invalideinput text-danger">{{
              errors[0]
            }}</small>
          </validation-provider> -->
        </b-form-group></b-col
      >
      <b-col sm="6">
        <!-- communes -->
        <b-form-group label="Commune">
          <b-form-input v-model="form.commune" type="text" disabled />
          <!-- <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-select
              v-model="form.commune"
              type="text"
              :options="communes"
              label="name"
              name="commune"
              placeholder="Sélectionnez votre commune"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->
        </b-form-group></b-col
      >
      <b-col sm="6">
        <!-- arrondissement -->
        <b-form-group label="Arrondissement">
          <b-form-input v-model="form.arrondissement" type="text" disabled />
          <!-- <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-select
              v-model="form.arrondissement"
              type="text"
              :options="arrondissements"
              label="name"
              placeholder="Sélectionnez votre arrondissement"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <!-- quartier -->
        <b-form-group label="Quartier/Village">
          <b-form-input v-model="form.quartier" type="text" disabled />
          <!-- <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-select
                      v-model="form.quartier"
                      type="text"
                      :options="quartiers"
                      label="name"
                      placeholder="Sélectionnez votre quartier/village"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
        </b-form-group></b-col
      >
      <!-- <b-col cols="12">
        <DepartementCommuneArrondissementVillageForm
          :trigger-form-validations="validateForm"
          @input="onDepartementFormChange"
        />

      </b-col> -->
    </b-row>
    <b-row>
      <b-col v-if="showEditionButtons" cols="12" class="my-2 text-right">
        <div class="">
          <b-button variant="outline-secondary" @click="onCancel">
            Cancel
          </b-button>
          <b-button variant="primary" @click="submitForm"> Modifier </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import utilsService from "@/services/utils/utils.service";
import DepartementCommuneArrondissementVillageForm from "@/components/DepartementCommuneArrondissementVillageForm.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import vSelect from "vue-select";

export default {
  setup: () => ({ $v: useVuelidate({ $lazy: true }) }),
  components: {
    vSelect,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    // BFormCheckbox,
    // BFormRadio,
    DepartementCommuneArrondissementVillageForm,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      annees: [],
      mois: [],
      form: {
        // annee: null,
        // mois: null,
        departement: "",
        commune: "",
        arrondissement: "",
        quartier: "",
      },
      validateForm: false,
    };
  }, //
  validations() {
    return {
      form: {
        // annee: { required },
        // mois: { required },
        departement: { required },
        commune: { required },
        arrondissement: { required },
        quartier: { required },
      },
    };
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(val, old) {
        this.$emit("onFormSuccess", {
          annee: val.annee,
          mois: val.mois,
        });
      },
    },
    triggerFormValidations: {
      handler(val, old) {
        if (val === true) this.submitForm();
      },
    },
    inputData: {
      immediate: true,
      deep: true,
      handler(val, old) {
        this.form = { ...this.form, ...(val || {}) };
      },
    },
  },
  computed: {
    ...mapState("params", {
      listMois: "listMois",
      listAnnees: "listAnnees",
    }),
  },
  props: {
    triggerFormValidations: {
      type: Boolean,
      default: false,
    },
    inputData: {
      type: Object,
      default: () => {},
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.annees = utilsService.getListYears();
    this.mois = utilsService.getListMonths();
    // this.handleYearsAndMonthsChanges();
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    handleYearsAndMonthsChanges() {
      const today = new Date();

      // get previous months
      const month = today.getMonth();
      const previousMonthId = month - 1;
      const previousMonth =
        this.mois[previousMonthId < 0 ? 11 : previousMonthId];

      // get revious months year
      today.setMonth(previousMonthId);
      const previousMonthsYear = today.getFullYear();

      // filter month and year select options
      this.mois = this.mois.filter((e) => e === previousMonth);
      this.annees = this.annees.filter((e) => e === previousMonthsYear);
      
      // initial values
      const { annee, mois } = this.form;
      const isNotNull = (val) => val && val.trim() !== "";
      this.form.annee = previousMonthsYear;
      this.form.mois = previousMonth;
    },
    onCancel() {
      this.$emit("onCancel", {});
    },
    onDepartementFormChange(evt) {
      this.form = { ...this.form, ...evt };
    },
    async submitForm() {
      this.validateForm = true;
      const isFormCorrect = await this.$v.$validate();
      if (!isFormCorrect) return;
      if (this.inputData) {
        this.$emit("onFormSuccess", this.form);
      } else {
        this.$emit("onFormSucess", {
          routerParams: { preview: true, data: this.form },
        });
      }
    },
  },
};
</script>

<style lang="scss">
// @import '@core/scss/base/themes/bordered-layout.scss';
</style>
